import React from 'react';
import { Link } from 'gatsby';

const Navbar = (props) => (
  <nav className="navigation-bar">
    <ul>
      <Link to="/"><li>Quiénes Somos</li></Link>
      <Link to="/Portfolio/"><li>Nuestro Trabajo</li></Link>
      <Link to="/Contacto/"><li>Contacto</li></Link>
    </ul>
  </nav>
)


export default Navbar;
