import React from 'react';
import Bumbalogo from '../images/700x250.gif'
import Navbar from './Navbar'

const Header = () => (
  <header>
    <div className="title-image">
      <img className="title-image" src={Bumbalogo} alt="BUMBA Logo"/>
    </div>
    <Navbar />
  </header>
)

export default Header;
