import React from 'react';
import Header from '../components/Header.js';

function Layout ({ children }) {
  return (
    <div className="App">
      <div className="main-container">
      <Header />
      {children}
      <footer>- Copyright BUMBA Editorial - </footer>
      </div>
    </div>
  );
}

export default Layout;
